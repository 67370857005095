$(document).ready(function () {
    //global
    common();
    initJumpMenu();
    goToTop();
    loadGoogleFont();
    
    //index
    bannerSlider();
    question();
  
    //blog
    blogSharing();
    autoGenerateRwdTableInEditor();
});

function bannerSlider() {

    var $bannerSlider = $('#bannerSlider');
    if ($bannerSlider.length > 0) {
        console.log('init banner');

        if ($bannerSlider.find('.ms-view').length == 0) {
            setMasterSliderImageOnScreen($bannerSlider);

            try {
                var slider = new MasterSlider();

                slider.control('timebar', {
                    insertTo: '#bannerSlider'
                });
                slider.control('bullets');

                slider.setup('bannerSlider', {
                    width: 1441,
                    // height: 721,
                    autoHeight:true,
                    minHeight: 400,
                    start: 1,
                    space: 0,
                    layout: 'fullwidth',
                    loop: true,
                    preload: 0,
                    instantStartLayers: false,
                    autoplay: true,
                    overPause: true
                });
                $('.master-skeleton-loader').remove();

            } catch (err) {
                console.error(err);
                removeErrorMasterSliderContainer($bannerSlider);
            }
        }

    }
}


function initJumpMenu() {

    //選單跳段落
    $('.nav a').click(function () {

        console.log('click menu link');

        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') || location.hostname == this.hostname) {
            jumpSection(this.hash);
        }
        // return false;
    });

    //處理編輯器要跳段落
    $('.editor a').click(function () {
        var $anchor = $(this);
        console.log('click editor internal link');
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') || location.hostname == this.hostname) {

            if ($anchor.attr('href').indexOf('http') < 0) {
                //不是完整連結才要跳
                jumpSection(this.hash);
                // return false;
            }

        }
    });

    //關閉手機選單
    $('body').click(function (e) {
        // only do this if navigation is visible, otherwise you see jump in navigation while collapse() is called 
        if ($('.navbar-collapse').is(':visible') && $('.navbar-toggle').is(':visible')) {
            //新增判斷，如果點擊的元素不在下拉子選單內，就關閉選單
            if ($(e.target).is('a:not(".dropdown-toggle")')) {
                $('.navbar-collapse').collapse('toggle');
            }

        }
    });

    /*才能避免slider產生後的高度讓跳cut不正確*/
    //超連結跳段落
    var jumpHash = location.hash;
    if (jumpHash !== '') {
        var newjumpHash = jumpHash.replace(/\//, '');
        console.log('detect jump from link url:' + newjumpHash);
        Pace.on('done', function () {
            jumpSection(newjumpHash);
        });
    }


}

//解決沒有要用按鈕客戶的產品在mobile會異常的問題
//不變更html
function productBoxLink() {
    var $productOverlay = $('.product-overlay');
    $productOverlay.click(function () {
        var $overlay = $(this);
        var url = $overlay.find('a').attr('href');
        window.location.href = url;
    });
}

function goToTop() {
    var $scrollToTop = $('.scrollToTop');
    $(window).scroll(function () {
        if ($(this).scrollTop() > 300) {
            $scrollToTop.fadeIn();
        } else {
            $scrollToTop.fadeOut();
        }
    });

    //Click event to scroll to top
    var $rootElemHtmlBody = $('html, body');
    $scrollToTop.click(function () {
        $rootElemHtmlBody.animate({
            scrollTop: 0
        }, 800);
        return false;
    });

    $('#footer-to-top').click(function () {
        $rootElemHtmlBody.animate({
            scrollTop: 0
        }, 800);
        return false;
    });

}


function question() {

    if (window.JUMP_DIR != '') {
        var $q4Elem = $("#" + window.JUMP_DIR);
        if ($q4Elem.length > 0) {
            $('html, body').animate({
                scrollTop: $q4Elem.offset().top
            }, 2000);

        }
    }

    var $questions =
        $('.questions');

    var $questionsTitle =
        $questions.find('li > h3');

    $questionsTitle.prepend('<i class="fa fa-caret-right" aria-hidden="true"></i> ');

    $questionsTitle.click(function (e) {

        var $expand = $(this).find('i');
        // console.log($expand);
        if ($expand.hasClass('fa-caret-right')) {
            //open
            var $answer =
                $(this).next();
            $answer.show();

            $expand.removeClass('fa-caret-right')
                .addClass('fa-caret-down');
        } else {
            //close
            var $answer =
                $(this).next();
            $answer.hide();

            $expand.removeClass('fa-caret-down')
                .addClass('fa-caret-right');
        }

    });

    var $answers =
        $questions.find('li > div');
    $answers.hide();

}

function blogSharing() {
    initJsSocial($('#blogSharing'));
}

function initJsSocial($targetElem) {
    if ($targetElem.length > 0) {
        $targetElem.jsSocials({
            shares: ["twitter", "facebook", "linkedin"]
        });
    }
}

// customer
function resetJsSocial() {
    jsSocials.shares.facebook = {
        label: "Like",
        // logo: "fa fa-facebook",
        logo: MYAPP.endpoint + 'build/fufann/images/icons/facebook.png',

        shareUrl: "https://facebook.com/sharer/sharer.php?u={url}",
        countUrl: "https://graph.facebook.com/?id={url}",
        getCount: function (data) {
            return data.share && data.share.share_count || 0;
        }

    };

    jsSocials.shares.googleplus = {

        label: "+1",
        // logo: "fa fa-google",
        logo: MYAPP.endpoint + 'build/fufann/images/icons/google-plus.png',
        shareUrl: "https://plus.google.com/share?url={url}",
        countUrl: ""

    };

    jsSocials.shares.linkedin = {
        label: "Share",
        // logo: "fa fa-linkedin",
        logo: MYAPP.endpoint + 'build/fufann/images/icons/linkedin.png',

        shareUrl: "https://www.linkedin.com/shareArticle?mini=true&url={url}",
        countUrl: "https://www.linkedin.com/countserv/count/share?format=jsonp&url={url}&callback=?",
        getCount: function (data) {
            return data.count;
        }
    };

    jsSocials.shares.twitter = {
        logo: MYAPP.endpoint + 'build/fufann/images/icons/twitter.png',
        label: "Tweet",
        // logo: "fa fa-twitter",
        shareUrl: "https://twitter.com/share?url={url}&text={text}&via={via}&hashtags={hashtags}",
        countUrl: "https://cdn.api.twitter.com/1/urls/count.json?url={url}&callback=?",
        getCount: function (data) {
            return data.count;
        }
    };

}



//第三方iniview載入機制
function initViewLazyOfIframe($targetElem) {
    var isEnable = false;
    if ($targetElem.length > 0) {
        $targetElem.on('inview', function (event, isInView) {
            if (isInView) {
                if (!isEnable) {
                    $targetElem.attr('src', $targetElem.attr('data-url'));
                    isEnable = true;
                }
            }
        });
    }
}

function lazyYoutube() {
    initLazyLoadOfYoutube($('#homeYt'));
}

function loadGoogleFont() {
    Pace.on('done', function () {
        WebFont.load({
            timeout: 2000,
            google: {
                families: ['Noto Serif TC&display=swap']
            }
        });
    });
}
