$(function () {
    //common
    fixedHeader();
    menuToggle();
    //blog
    copySharkLink();
    //index
    owlBlogs();
});

function fixedHeader() {
    //固定選單
    var $body = $('body');
    var $header = $('.header');

    var headerHeight = $header.height();
    var bodyHeight = $body.height() * 0.2;
    console.log('bodyHeight:' + bodyHeight);
    $(window).scroll(function () {

        var st = $(this).scrollTop();
        console.log('[fixedHeader] pos:' + st);

        if (st <= 40) {

            if ($header.hasClass('fixed')) {

                $header.removeClass('fixed');
                $body.css({
                    marginTop: 0
                });
                console.log('[fixedHeader]remove fixed!!');
            }


        } else {
            if (st > bodyHeight) {
                if ($header.hasClass('fixed') == false) {

                    $header.addClass('fixed');

                    //因為固定選單時，一開始的選單就不是設fixed的
                    //因此要多判斷一下目前的高度多少多一個margin-top，否則畫面會被卡到
                    $body.css({
                        marginTop: headerHeight
                    });
                    console.log('[fixedHeader] fixed!! => header w:' + headerHeight);
                }

            } else {
                if ($header.hasClass('fixed')) {
                    $body.css({
                        marginTop: 0
                    });
                    $header.removeClass('fixed');
                    console.log('[fixedHeader]remove fixed!!');
                }
            }
        }

    });
}

function menuToggle() {

    var $header = $('.header');
    //選單mask
    var $menuListBackdrop = $('.menu-list-backdrop');
    //側欄選單
    var $menuList = $(".menu-list");
    var $menuClose = $(".menu-list .close");
    var $menuToggle = $(".menu-toggle");

    $menuToggle.on("click", function () {
        $menuList.toggleClass("active");
        $header.toggleClass("open-menu");
    });
    $menuClose.on("click", function () {
        $menuList.toggleClass("active");
        $header.toggleClass("open-menu");
    });
    $menuListBackdrop.on("click", function () {
        $menuClose.trigger('click');
    });

    //側欄子選單
    var $subMenuByHeading = $menuList.find('.item-heading');

    $subMenuByHeading.on("click", function () {

        //$menuList.toggleClass("active");
        var $subMenu = $(this).find('.small-items');

        var isShow = false;
        if ($subMenu.hasClass('active')) {
            isShow = true;
        }
        $subMenuByHeading.find('.small-items').removeClass("active");

        // $subMenu.toggleClass("active");
        if (isShow == false) {
            console.log('[menuToggle] open submenu');
            $subMenu.addClass('active');
        } else {
            console.log('[menuToggle] close submenu');
            $subMenu.removeClass('active');
        }
    });

}

function copySharkLink() {
    var $sharkLink = $('#share-blog-link');
    var link = '';
    var $copyTip = $('.copy-tip');
    $('.btn-copylink').on('click', function () {
        var link = $sharkLink.select();
        document.execCommand("copy");
        $copyTip.fadeIn(500).fadeOut(2000);
    });

}

function owlBlogs() {
    console.log('owlBlogs');

    owlSlider(
        $('.owl-news'),
        1,
        1, {
            dots: true,
            center: true,
            responsive: {
                0: {
                    dots: true,
                    center: true,
                }
            }
        });
    owlSlider(
        $('.owl-knowledge'),
        1,
        1, {
            dots: true,
            center: true,
            responsive: {
                0: {
                    dots: true,
                    center: true,
                }
            }
        });
    owlSlider(
        $('.owl-popular'),
        1,
        1, {
            dots: true,
            center: true,
            responsive: {
                0: {
                    dots: true,
                    center: true,
                }
            }
        });
}

function owlSlider($owlSlider, items, slideBy, newOpts) {

    console.log('owlSlider');

    var defaults = {
        autoplay: 1000,
        loop: true,
        // animateOut: 'fadeOut',
        // animateIn: 'fadeIn',
        margin: 10,
        // autoWidth: true,
        authHeight: false,
        stagePadding: 10,
        margin: 10,
        items: items,
        dots: true,
        nav: false,
        // lazyLoad: true,
        slideBy: slideBy,
        dotsContainer: null
    }

    //客變dots
    var owlDotsContainerId = '#' + $owlSlider.attr('data-id') + '-dots-container';
    var owlDotsId = '#' + $owlSlider.attr('data-id') + '-dots';
    // alert('owlDotsId:' + owlDotsId);
    var $owlDotsContainer = $(owlDotsContainerId);
    // alert('owlDotsId:' + $owlDots.length);
    defaults.dotsContainer = owlDotsId;



    var $thumbId = $('#' + $owlSlider.attr('data-thumb-id'));
    console.log('owl thumb id:' + $owlSlider.attr('data-thumb-id'));

    var opts = $.extend({}, defaults, newOpts);

    // function callback(event) {
    //     // Provided by the core
    //     var element   = event.target;         // DOM element, in this example .owl-carousel
    //     var name      = event.type;           // Name of the event, in this example dragged
    //     var namespace = event.namespace;      // Namespace of the event, in this example owl.carousel
    //     var items     = event.item.count;     // Number of items
    //     var item      = event.item.index;     // Position of the current item
    //     // Provided by the navigation plugin
    //     var pages     = event.page.count;     // Number of pages
    //     var page      = event.page.index;     // Position of the current page
    //     var size      = event.page.size;      // Number of items per page
    // }
   
    function getCurrentSlide(element, slideIndex) {
        console.log('getCurrentSlide:' + slideIndex);
        //owl 主元素
        // console.log(element);
        //透過當前索引
        var $owlItem = $(element).find('.owl-item').eq(slideIndex);
        var $currentInfoText = $owlItem.find('.info-text');
        var imgUrl = $currentInfoText.attr('data-img');
        var linkUrl = $currentInfoText.attr('data-link');
        var animateCss = $currentInfoText.attr('data-animate');

        if ($(window).width() <= 576) {
            // console.log('mobile device!!');
            animateCss = 'animate__animated animate__fadeInRight';
        }
        console.log(slideIndex + ') owl data-title:' + $currentInfoText.attr('data-title'));
        console.log(slideIndex + ') owl data-img:' + imgUrl);
        console.log(slideIndex + ') owl data-link:' + linkUrl);

        $thumbId.attr('href', linkUrl);
        $thumbId.find('img').attr('src', imgUrl);

        console.log($thumbId);
        var $thumbDiv = $thumbId.parent();
        $thumbDiv.on("animationend", function () {
            // 監聽當動畫結束後移除 class
            $(this).removeClass(animateCss);
        });
        $thumbDiv.addClass(animateCss);

          //dot read more=>為了鎖住
        $owlDotsContainer.find('.readmore').click(function () {
            console.log('readmore:' + linkUrl);
            window.location.href = linkUrl;
        });

    };

    $owlSlider.on('initialize.owl.carousel', function (event) {
        var element = event.target;
        getCurrentSlide(element, event.item.index);
    });

    $owlSlider.on('changed.owl.carousel', function (event) {
        console.log('changed.owl.carousel');
        var element = event.target;
        getCurrentSlide(element, event.item.index);
    });

  

    $owlSlider.owlCarousel(opts);

    /*
    影片滑入效果
    */
    var $owlImages = $owlSlider.find('img');
    $owlImages.on('inview', function (event, isInView) {
        if (isInView) {
            console.log('owl img inview');
            var $img = $(this);
            // console.log($img);
            $img.attr('src', $img.attr('data-lazy'));

            $img.removeAttr('data-lazy');

            var $parentLink = $img.parent();
            console.log($parentLink);
            console.log('attr animate:' + $parentLink.attr('animate'));
            $parentLink.on("animationend", function () {
                // 監聽當動畫結束後移除 class
                $(this).removeClass("animate__animated animate__fadeInLeft");
            });
            $parentLink.addClass("animate__animated animate__fadeInLeft");
        }
    });

    $pElems = $owlSlider.find('.owl-item');
    if ($pElems.length > 0) {

        for (var i = 0; i < $pElems.length; i++) {
            var $pTmp = $($pElems[i]);

            // var $truncateTitle = $pTmp.find('.truncate-desc');

            var $truncateDesc = $pTmp.find('.truncate-desc');

            console.log('==' + i + '==');
            console.log($truncateDesc);
            truncateElem($truncateDesc, 50);

        }
    }


}

function truncateElem($elem, truncateSize) {
    var innerText = $elem.text().trim();
    console.log('before truncate')
    console.log(innerText);

    // var truncateSize = 100;
    if (MYAPP.i18n.current_country == 'us') {
        truncateSize = 80;
    }
    var truncateInnerText;
    if (innerText.length >= truncateSize) {

        truncateInnerText =
            innerText.substring(0, truncateSize) + '...';

        $elem.text(truncateInnerText);

    } else {
        truncateInnerText = innerText;
    }
    console.log('after truncate');
    console.log(truncateInnerText);

}